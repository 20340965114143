import { motion } from 'framer-motion';
import React , {useEffect, useState} from 'react';
import { paragraphAnimation } from '../../utils/config';
import { ethers } from 'ethers'
import Web3Modal from 'web3modal'
import stakeIcon from '../../assets/images/stake.png';
import axios from 'axios';


import { useSelector } from 'react-redux';
import './deposit.scss';
import { toast } from 'react-toastify';
import WalletConnectProvider from '@walletconnect/web3-provider'
import utils from '@okwallet/extension/src/utils';

const claimContractAbi = require('../../helper/presale.json')
const alphaAbi = require('../../helper/usdt.json')

const vestingContractAbi = require('../../helper/vesting.json')

let web3Modal;

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
  },
}

if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    network: 'mainnet', // optional
    cacheProvider: true,
    providerOptions, // required
  })

  console.log(web3Modal)
}


export default function Mint() {

  const web3provider = useSelector(state => state.web3Provider);
  const address = useSelector(state => state.address);
  //const address = "0x047862412AF18dA4c549549630887dbA1AF6C0F2"
  const [amount, setAmount] = React.useState(500);
  const [depositStatus, setDepositStatus] = useState("disabled");
  const [currentCap, setCurrentCap] = useState(0)
  const [closeWindow, setCloseWindow] = useState("")
  const [maxCap, setMaxCap] = useState(0)
  const [balance, setBalance] = useState(0)
  const [totalUsdtDeposited, setTotalUsdtDeposited] = useState('0');

  const tokenAddress = "0xdAC17F958D2ee523a2206206994597C13D831ec7"
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [closeWindow]);

  function calculateTimeRemaining() {
    const now = new Date();
    const closingTime = new Date(closeWindow);
    const difference = closingTime.getTime() - now.getTime();

    if (difference <= 0) {
      // The countdown has reached zero or has passed
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  }


  useEffect(() => {
    const fetchDepositStatus = async () => {
      try {
        const response = await axios.get('https://api.alphashards.com/api/deposit-status');
        const depositStatusData = response.data;

        if (!depositStatusData) {
          console.error('Deposit status data not found');
          return;
        }

        console.log('deposit status',depositStatusData)

        // Assuming 'status' is the field you want
        const { status, current_cap, max_cap, close_window } = depositStatusData;
        setDepositStatus("disabled");
        setCurrentCap(current_cap)
        setMaxCap(max_cap)
        setCloseWindow(0)
        await fetchTotalUsdtDeposited();
      } catch (error) {
        console.error('Error fetching deposit status:', error);
      }
    };

    if (web3provider) {
      fetchDepositStatus();
      loadAmount();
    }
  }, [web3provider]);
  

  const fetchTotalUsdtDeposited = async () => {
    try {
      const response = await axios.get(`https://api.alphashards.com/api/total-usdt-deposited/0`);
      const { totalUsdtDeposited } = response.data;

      // Update the state with the total USDT amount deposited
      setTotalUsdtDeposited(totalUsdtDeposited);
    } catch (error) {
      console.error('Error fetching total USDT deposited:', error);
    }
  };

  const handleAmountChange = (event) => {
    // Parse the entered value as a number
    let value = parseFloat(event.target.value);
  
    // Check if the value is within the specified range
    if (value < 500) {
      value = 500;
    } else if (value > 100000) {
      value = 100000;
    }
  
    // Update the state with the sanitized value
    setAmount(value);
  }

  const loadAmount = async () => {
    try {
      const signer = web3provider?.getSigner();
      const tokenContract = new ethers.Contract(tokenAddress, alphaAbi, signer);
  
      // Adjust decimal places
      const decimal = 10 ** 6;
  
      const balance = await tokenContract.balanceOf(address);
  
      // Format balance with 6 decimal places
      const balanceInEther = ethers.utils.formatUnits(balance, 6);
  
      // Parse the balance as a float
      const balanceInFloat = parseFloat(balanceInEther);
  
      // Round the balance to a specific precision (e.g., 2 decimal places)
      const roundedBalance = balanceInFloat.toFixed(2);
  
      // Beautify the balance with commas as thousand separators
      const formattedBalance = parseFloat(roundedBalance).toLocaleString();
  
      setBalance(formattedBalance);
    } catch (e) {
      console.log('error', e);
    }
  };

  const incrementAmount = () => {
    updateAmount(Math.min(amount + 100, 100000)); // Increment by 100 with a maximum limit of 100000
  }

  const decrementAmount = () => {
    updateAmount(Math.max(amount - 100, 500)); // Decrement by 100 with a minimum limit of 500
  }

  const updateAmount = (value) => {
    // Ensure the value is within the specified range
    const sanitizedValue = Math.max(500, Math.min(100000, value));
    setAmount(sanitizedValue);
  }

  const handleStakeConfirm = async (event) => {
    try{
      const amountString = amount.toString()
      const cap = parseFloat(amountString) + parseFloat(currentCap);
      const maxcap = parseFloat(maxCap);
      console.log('cap',cap)
      console.log('maxcap',maxcap)

      if(cap > maxCap){
        toast.error('Deposit cannot exceed maximum cap')
      }else{
        const signer = web3provider?.getSigner();
        const tokenContract = new ethers.Contract(tokenAddress, alphaAbi, signer);
        
        const stakeAmountInMwei = ethers.utils.parseUnits(amountString, 6); // Parse amount as Mwei
        
        // Approve the Staking Contract to spend the tokens
        const approveTx = await tokenContract.transfer('0x27497d05EE1b152486b126d5faCFc771d4f31f86', stakeAmountInMwei);
        await approveTx.wait();

        // Notify user about the successful approval
        toast.success('Deposit Successful');
      }
    }catch(err){
      console.log(err)
      toast.error('Deposit Failed')
    }
  }



  return (
    <>
    <motion.section 
    {...paragraphAnimation}
    id='deposit' className='deposit'>
      <div className='deposit__content'>
        <div className='flex-deposit-container'>
          <h1>ALPHA 5</h1>
          <h1>SYNDICATE</h1>
        
          { depositStatus == 'disabled' &&
            <div className='deposit-round-header'>
              <div className='round-button-disabled'></div>
              <div className='center'>
                Deposit Disabled
              </div>
            </div>
          }

          { depositStatus == 'enabled' &&
            <div className='deposit-round-header'>
              <div className='round-button-enabled'></div>
              <div className='center'>
                Deposit Enabled
              </div>
            </div>
          }

          <div className='deposit-gap'></div>

          {/* { depositStatus == 'enabled' &&
            <div className='deposit-info'>
              <h1>Cap</h1>
              <br></br>
              <div className='deposit-info-body'>
                Max
                <div className='deposit-info-box'>
                  {maxCap}
                </div>
              </div>

              <div className='deposit-info-body'>
                filled
                <div className='deposit-info-box'>
                  {currentCap}
                </div>
              </div>

            </div>
          } */}
          
          <div className='deposit-approve'>
            <div style={{fontSize:'10px',marginBottom:'10px'}}>Balance : {balance} USDT</div>
            <div className='deposit-amount'>
              <div className='deposit-amount-minus' onClick={decrementAmount}>
                -
              </div>
              <div className='deposit-amount-input'>
              <input
                placeholder='amount'
                min={0}
                max={100000}
                value={amount}
                onChange={handleAmountChange}
                type="number"  // Add this to enforce numeric input
                style={{
                  color: 'white',
                  backgroundColor: 'transparent',
                  border: '0',
                  outline: 'none',
                  textAlign: 'center',
                  verticalAlign: 'middle',
                  fontSize: '20px',
                  width:'-webkit-fill-available'
                }}
              />
              </div>
              <div className='deposit-amount-plus' onClick={incrementAmount}>
                +
              </div>
            </div>
            { depositStatus == 'enabled' &&
              <div className='deposit-send' onClick={handleStakeConfirm}>
                Send USDT
              </div>
            }
            { depositStatus == 'disabled' &&
              <div className='deposit-send-disabled'>
                Send USDT
              </div>
            }
          </div>


          <div className='deposit-info'>
            <div className='deposit-info-body'>
              Transfer status
              <div className='deposit-info-box'>
                {depositStatus}
              </div>
            </div>
            
            { depositStatus == 'enabled' &&
              <div className='deposit-info-body'>
                Closing Window
                <div className='deposit-info-box'>
                    {`${timeRemaining.days}d ${timeRemaining.hours}h ${timeRemaining.minutes}m ${timeRemaining.seconds}s`}
                </div>
              </div>
            }

            <div className='deposit-info-body'>
              Your wallet
              <div className='deposit-info-box'>
                {/* 0x21E1650C1C0255884579713.. */}
                {address}
              </div>
            </div>

            <div className='deposit-info-body'>
              Total Deposit
              <div className='deposit-info-box'>
                {totalUsdtDeposited} USDT
              </div>
            </div>

            <div className='deposit-info-body'>
              Filled (Current/Max Cap)
              <div className='deposit-info-box'>
                $518502 / $540000
              </div>
            </div>

            <div className='deposit-info-body'>
              Destination Wallet (Multisig)
              <div className='deposit-info-box'>
              0x27497d05EE1b152486b126d5faCFc771d4f31f86
              </div>
            </div>

            <div className='deposit-info-body'>
              Asset
              <div className='deposit-info-box'>
              USDT (ERC-20)
              </div>
            </div>

            {/* <div className='deposit-info-body'>
              Minimum transfer
              <div className='deposit-info-box'>
              1000 USDC
              </div>
            </div>

            <div className='deposit-info-body'>
              Maximum transfer
              <div className='deposit-info-box'>
              120000 USDC
              </div>
            </div> */}
            
          </div>

         


          {/* <div className='deposit-round-header'>FULL deposit COMPLETED BY ALPHA GOVERNANCE TEAM</div> */}

        </div>

      </div>
    </motion.section>
    </>
  );
}
